<template>
    <div>
        <!-- MODALS ================================================== -->
        <modal name="my-modal" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h4 class="card-header-title">
                            Tambah Unit
                        </h4>
                        <!-- Close -->
                        <button type="button" class="close" @click="hide_modal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form v-on:submit.prevent="handleSubmit" >
                        <div class="card-body">
                            <div class="form-group">
                                <label>Nama Unit</label>
                                <input type="text" v-model="form.title" name="title" value="" class="form-control" placeholder="" required>
                            </div>
                            <div class="form-group">
                                <label>Deskripsi Unit<span class="text-danger">*</span></label>
                                <textarea style="height:100px" type="text" name="description" v-model="form.description" value="" required class="form-control" placeholder=""></textarea>
                            </div>
                            <div class="form-group mb-4 border-bottom pb-4 w-100">
                                <label>Upload Cover</label>
                                
                                <div class="custom-file" >
                                    <input type="file" :name="'file_attachment'"  style="cursor:pointer !important" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01"  @change="previewFiles($event)"  accept="image/x-png,image/jpg,image/jpeg">
                                    <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                </div>

                                <div class="d-none mt-3" :id="'file-uploaded-section'">
                                    <div class="mb-3">
                                        <div class="file-uploaded-area" :id="'file-uploaded-area'"></div>
                                    </div>
                                </div>
                                
                                <div class="mt-3" :id="'file-uploaded-section-before'" >
                                    <div class="mb-3">
                                        <div :id="'file-uploaded-area-temp'" class="file-uploaded-area" v-bind:style="{ 'background-image': 'url(https://scolacdn.com/cms/default/default_banner.jpg)' }"></div>
                                    </div>
                                </div>
                             </div>
                        </div>

                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <progress class="d-none" :id="'upload-bar'" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal & Tutup</button>
                                <button disabled :id="'progress-button'" type="button" class="btn btn-primary d-none">Uploading {{uploadPercentage}}%...</button>
                                <button :id="'upload-button'" type="submit" class="btn btn-primary">Simpan</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- HEADER -->
                <div class="d-block pt-3 mb-4">
                    <div class="row align-items-center">
                        <div class="col">
                            <h1 class="header-title">
                                Master Unit PPDB
                                <small class="form-text text-muted mt-2">
                                    Menu ini Digunakan Untuk Mengelola Unit PPDB
                                </small>
                            </h1>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-primary ml-2" @click="show_modal()">
                                <div class="d-flex align-items-center">
                                    <i class="fe fe-plus mr-2"></i>Tambah Unit
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- CONTENT -->
                <div class="tab-content">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari unit...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-sm table-nowrap card-table">
                                    <thead>
                                        <tr>
                                            <th style="width:8%">No</th>
                                            <th style="width:20%">Nama Unit</th>
                                            <th>Deskripsi Unit</th>
                                            <th style="width:10%">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="fetching">
                                        <tr>
                                            <td colspan="5">
                                                <div class="alert alert-primary" role="alert">
                                                    Sedang mengambil data..
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!fetching">
                                        <tr v-for="(unit, index) in dataUnit" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>{{ unit.title }}</td>
                                            <td>{{ unit.description }}</td>
                                            <td class="text-center">
                                                <button @click.prevent="editForm(unit.id)" class="btn btn-info btn-sm mr-2"><i class="fe fe-edit mr-2"></i>Edit</button>
                                                <button @click.prevent="postDelete(unit.id)" class="btn btn-danger btn-sm mr-2"><i class="fe fe-trash-2 mr-2"></i>Delete</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Master Unit'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                dataUnit: [],
                file_attachment : [],
                submitted: false,
                fetching: true,
                uploadPercentage: 0,
                form: {
                    id: '',
                    title: '',
                    description: '',
                    cover:''
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: ''
            }
        },
        created() {
            this.getData();
        },
        components: {
            vPagination
        },
        methods: {
            handleSubmit() {
                this.submitted = true;
                const upload_bar = document.getElementById("upload-bar");
                const progress_button = document.getElementById("progress-button");
                const upload_button = document.getElementById("upload-button");

                upload_button.classList.add("d-none");
                upload_bar.classList.remove("d-none");
                progress_button.classList.remove("d-none");

                if(this.form.cover.name) {
                    this.uploadFile = true;

                    const callback  = this.$apiconfig + 'admin/master/unit/save';
                    let formData    = new FormData();
                    
                    formData.append('file[]', this.form.cover);
                    formData.append('file_path', "coverUnit/");
                    formData.append('callback', callback);

                    this.$http.post(this.$api_upload_engine, formData , {
                        onUploadProgress: function(progressEvent) {
                            this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        }.bind(this)
                    })
                    .then(response => {
                        this.uploadFile = false;
                        const res       = response.data;

                        if (res.status && res.result != "") {
                            this.saveData(res.result[0]['path']);
                        } else {
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Gagal mengunggah file, coba lagi nanti!',
                            })
                        }
                    });
                } else {
                    this.saveData(this.form.cover);
                }
                
            },
            show_modal() {
                this.form.id = '';
                this.form.title = '';
                this.form.description = '';
                this.$modal.show('my-modal');
            },
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            // Get data data periode
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/masterunit', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.dataUnit = response.data.data;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB

                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {

                    this.preview_image = URL.createObjectURL(file);
                    const preview_area = document.getElementById("file-uploaded-section");
                    const prebview_before = document.getElementById("file-uploaded-section-before");
                    const preview_area_image = document.getElementById("file-uploaded-area");
                   
                    preview_area.classList.remove("d-none");
                    prebview_before.classList.add("d-none");
                    preview_area_image.style.backgroundImage = "url('"+this.preview_image+"')";

                    this.form.cover = file;
                }
            },
            saveData : function(foto) {
                const upload_bar = document.getElementById("upload-bar");
                const progress_button = document.getElementById("progress-button");
                const upload_button = document.getElementById("upload-button");

                this.$http.post(this.$apiconfig  + 'admin/master/unit/save', {
                    id: this.form.id,
                    title: this.form.title,
                    description: this.form.description,
                    file_attachment: foto,
                })
                    .then((response) => {
                        if(response.data.status){
                            this.$swal({
                            icon: 'success',
                            title: "success",
                            text: "Data Unit berhasil disimpan",
                            showConfirmButton: false,
                            timer: 1500
                            })
                            this.$modal.hide('my-modal')
                            this.getData()
                            this.form.title = ''
                            this.form.description = ''
                        }else{
                            this.$swal(
                                'Tidak dapat di ditambahkan!',
                                'Unit sudah ada.',
                                'info'
                            )
                        }
                        upload_bar.classList.add("d-none");
                        progress_button.classList.add("d-none");
                        upload_button.classList.remove("d-none");
                        
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
                
            },
            // Edit data periode
            editForm(id) {
                this.$http.get(this.baseUrl + `admin/master/unit/detail?id=${id}`)
                    .then((response) => {
                        this.form.id = response.data.data[0].id;
                        this.form.title = response.data.data[0].title;
                        this.form.description = response.data.data[0].description;
                        const preview_area_image = document.getElementById("file-uploaded-area-temp");
                        preview_area_image.style.backgroundImage = 'url("'+response.data.data[0].cover+'")'; 
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                this.$modal.show('my-modal');
            },
            // Delete data periode
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Data yang dihapus tidak dapat di kembalikan',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/master/unit/delete',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            if (response.data.status_pemakaian != 'digunakan') {
                                this.$swal(
                                    'Terhapus!',
                                    'Data berhasil dihapus.',
                                    'success'
                                )
                            } else {
                                this.$swal(
                                    'Tidak dapat di hapus!',
                                    'Unit sudah digunakan.',
                                    'info'
                                )
                            }
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>